<template>
  <div class="notice">
    <headerPage></headerPage>
    <div class="notice-page-content">
        <div class="notice-page-title">
            作业列表
        </div>
        <div class="notice-page-list">
            <div @click="toAssignInfo(item.chapterId)" v-for="(item,index) in data" class="notice-page-item">
                <div style="margin-left:20px">
                    {{index + 1}}. {{item.taskName + ' - ' + item.chapterName +' - 章节作业'}} 
                </div>
                <div style="margin-right:20px">
                    <i class="el-icon-bell"></i>
                </div>
            </div>
        </div>
        <div class="notice-page-bottom">
            <img style="width:100%" src="../../assets/image/45.png">
        </div>
    </div>
    <bottomPage></bottomPage>
  </div>
</template>

<script>
  import {getMyApeHomework} from "../../api/api"
  import headerPage from "../../components/header/header"
  import bottomPage from "../../components/bottom/bottom"
  export default {
    data() {
      return{
        data: []
      }
    },
    components: {
      headerPage,
      bottomPage
    },
    methods: {
      query() {
          this.loading = true
          getMyApeHomework()
            .then(res => {
              if(res.code == 1000) {
                this.data = res.data
                console.log('作业数据:', this.data)
              } else {
                this.$message.error(res.msg || '获取数据失败')
              }
            })
            .catch(err => {
              console.error('请求出错:', err)
              this.$message.error('网络请求失败')
            })
            .finally(() => {
              this.loading = false
            })
        },
        toAssignInfo(id) {
            this.$router.push("/assignInfo?id="+id)
        }
    },
    created() {
      this.query()  // 添加在created中也调用
    },
    mounted() {
      this.query()
    }
 }
</script>

<style scoped>
  @import url("../../assets/css/notice/notice.css");
  .notice-page-content {
  min-height: 400px;
  padding: 20px;
}

.notice-page-list {
  margin: 20px 0;
}

.notice-page-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}
</style>