<template>
  <div class="bottom">
          
      </div>
</template>

<script>
  export default {
    data() {
      return{
        
      }
    },
    methods: {
     
    },
    created() {
     
    },
    mounted() {
      
    }
 }
</script>

<style  scoped>
  .bottom {
    width: 100%;
    height: 16%;
    background-color: #262543;
    display: flex;
}
.bottom-one,.bottom-four {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: 100%;
    font-family: '黑体';
    font-size: 15px;
}
.bottom-two,.bottom-three {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20%;
    height: 100%;
    font-family: '黑体';
    font-size: 15px;
}
</style>