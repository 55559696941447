<template>
  <div class="index">
      <headerPage></headerPage>
      <div class="index-search">
          <div class="index-search-content">
              <div class="search-content">
                <el-input class="searchKey" v-model="searchKey" suffix-icon="el-icon-search" placeholder="请输入想要搜索的课程名称并按回车键搜索" @change="searchTask()"></el-input>
              </div>
            </div>
      </div>
      <div class="most-major">
          <div class="most-content"> 
              <div class="page-title">
                <div style="letter-spacing: 2px;">热门选修的专业</div>
                <div style="margin-top:10px;color:#92969B">Most <span style="color:#20B486">Popular Major</span></div>
              </div>
              <div class="major-list">
                <div class="major-item" v-for="(item,index) in major" :key="index">
                    <div class="major-name">
                        <i v-if="index == 0" style="font-size:21px" class="el-icon-medal-1"></i>
                        <i v-if="index == 1" style="font-size:21px" class="el-icon-lollipop"></i>
                        <i v-if="index == 2" style="font-size:21px" class="el-icon-milk-tea"></i>
                        <i v-if="index == 3" style="font-size:21px" class="el-icon-moon-night"></i>
                        <i v-if="index == 4" style="font-size:21px" class="el-icon-sunset"></i>
                        <i v-if="index == 5" style="font-size:21px" class="el-icon-truck"></i>
                        <i v-if="index == 6" style="font-size:21px" class="el-icon-bicycle"></i>
                        <i v-if="index == 7" style="font-size:21px" class="el-icon-timer"></i>
                        <i v-if="index == 8" style="font-size:21px" class="el-icon-mic"></i>
                        <i v-if="index == 9" style="font-size:21px" class="el-icon-cpu"></i>
                        <i v-if="index == 10" style="font-size:21px" class="el-icon-thumb"></i>
                        <i v-if="index == 11" style="font-size:21px" class="el-icon-mouse"></i>
                        <i v-if="index == 12" style="font-size:21px" class="el-icon-finished"></i>
                        <i v-if="index == 13" style="font-size:21px" class="el-icon-help"></i>
                        <i v-if="index == 14" style="font-size:21px" class="el-icon-odometer"></i>
                        <i v-if="index == 15" style="font-size:21px" class="el-icon-sugar"></i>
                        <div style="margin-left:6px;letter-spacing: 1px;">{{item.name}}</div>
                    </div>
                    <el-button @click="majorTask(item.name)" style="margin-right:6px" plain size="mini" type="success" icon="el-icon-top-right"></el-button>
                </div>
              </div>
          </div>
      </div>
    
      <div class="most-note">
          <div class="most-content">
              <div class="page-title">
                <div style="letter-spacing: 2px;">推荐笔记</div>
                <div style="margin-top:10px;color:#92969B">Recommended <span style="color:#20B486"> Notes</span></div>
              </div>
              <div class="note-list">
                  <div v-for="(item,index) in article" :key="index" class="note-item">
                     <div class="note-image"><img src="../../assets/image/index/Vector (1).png"></div>
                     <div class="note-user">
                         <img class="note-avatar" :src="$store.state.HOST + item.avatar">
                         <div class="note-username">
                             <div>{{item.createBy}} - {{item.createTime}}</div>
                             <div>{{item.title}}</div>
                         </div>
                     </div>
                     <div class="note-font">
                         {{item.articleDesc}}
                     </div>
                  </div>
              </div>
              <div class="task-btns">
                  <el-button @click="toArticle" size="mini" type="success" icon="el-icon-arrow-right"></el-button>
              </div>
          </div>
      </div>
      <div v-if="userInfo" class="most-cards">
        
      <div class="due-cards">
        
    <div class="most-content">
        <div class="page-title">
        <div style="letter-spacing: 2px;">待复习卡片</div>
        <div style="margin-top:10px;color:#92969B">Due <span style="color:#20B486">Cards</span></div>
      </div>
      <div class="cards-list">
        <div v-for="(card, index) in dueCards" :key="index" class="card-item">
          <div class="card-icon">
            <i class="el-icon-time" style="font-size:21px;color:#20B486"></i>
          </div>
          <div class="card-info">
            <div class="card-title">{{card.title}}</div>
            <div class="card-time">
              <span>到期时间: {{formatDate(card.due)}}</span>
              <span style="margin-left:15px">上次复习: {{formatDate(card.lastReview)}}</span>
            </div>
          </div>
          <el-button 
            @click="toReview(card)" 
            size="mini" 
            type="success" 
            icon="el-icon-refresh-right">
            复习
          </el-button>
        </div>
      </div>
    </div>
  </div>
</div>
      <bottomPage></bottomPage>
  </div>
</template>

<script>
  import {getApeMajorList,getIndexAchievement,getIndexArticleList,getDueCards} from '../../api/api' 
  import headerPage from "../../components/header/header"
  import bottomPage from "../../components/bottom/bottom" 
  export default {
    data() {
      return{
        userInfo: null,
        searchKey: "",
        major: [],
        dueCards: [],
        data: {
            count: 0,
            count1: 0,
            count2: 0,
            total: 0
        },
        article: [],
      }
    },
    components: {
		headerPage,
        bottomPage
	},
    methods: {
        searchTask() {
            if (this.searchKey) {
                this.$router.push("/task?name=" + this.searchKey)
            }
        },
        majorTask(major) {
            this.$router.push("/task?major=" + major)
        },
        toArticle() {
            this.$router.push("/article")
        },
        toMessage() {
            this.$router.push("/message")
        },
        getApeMajorList() {
			getApeMajorList().then(res => {
                if(res.code == 1000) {
                    var major = res.data
                    this.major = major.splice(0,16)
                } else {
                    this.$notify.error({
                    title: '错误',
                    message: res.message
                    });
                }
			})
        },
        getIndexAchievement() {
            getIndexAchievement().then(res => {
                if(res.code == 1000) {
                    this.data = res.data
                } else {
                    this.$notify.error({
                    title: '错误',
                    message: res.message
                    });
                }
            })
        },
        getIndexArticleList() {
            getIndexArticleList().then(res => {
                if(res.code == 1000) {
                    this.article = res.data
                } else {
                    this.$notify.error({
                    title: '错误',
                    message: res.message
                    });
                }
            })
        },
        getDueCardsList() {
            // 从localStorage获取用户信息
            const userInfo = JSON.parse(window.localStorage.getItem("user_info"))
            
            if (!userInfo) {
                console.log('用户未登录')
                return
            }
            

              // 修改日期格式为后端可接受的格式
            const currentTime = new Date().toISOString().split('T')[0] + ' ' + 
                     new Date().toTimeString().split(' ')[0]

            const params = {
                userId: userInfo.id,
                currentTime: currentTime  // 格式如: "2024-11-05 18:16:04"
            }
            console.log(params);
            
            getDueCards(params).then(res => {
                if(res.code == 1000) {
                this.dueCards = res.data
                console.log('获取到的到期卡片:', this.dueCards)
                } else {
                this.$notify.error({
                    title: '错误',
                    message: res.message
                })
                }
            }).catch(err => {
                console.error('获取到期卡片失败:', err)
            })
            },
    formatDate(date) {
      return new Date(date).toLocaleString()
    },
    toReview(card) {
      this.$router.push(`/assignInfo?id=${card.chapterId}`)
    }
    },
    created() {
     
    },
    mounted() {
      this.getApeMajorList()
      this.getIndexAchievement()
      this.getIndexArticleList()
          // 获取用户信息
      const userInfoStr = window.localStorage.getItem("user_info")
      if (userInfoStr) {
      this.userInfo = JSON.parse(userInfoStr)
      this.getDueCardsList()
    }
    }
 }
</script>

<style scoped>
  @import url("../../assets/css/index/index.css");

  .due-cards {
  width: 100%;
  background: #ffffff;
  padding: 40px 0;
}

.cards-list {
  margin-top: 20px;
}

.card-item {
  display: flex;
  align-items: center;
  padding: 15px;
  margin: 10px 0;
  border-radius: 8px;
  background: #f8f9fa;
  transition: all 0.3s;
}

.card-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
}

.card-icon {
  margin-right: 15px;
}

.card-info {
  flex: 1;
}

.card-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
}

.card-time {
  font-size: 13px;
  color: #92969B;
}

.most-cards {
  width: 100%;
  background: #ffffff;
  padding: 40px 0;
}

.due-cards {
  width: 1200px;  /* 固定宽度 */
  margin: 0 auto; /* 水平居中 */
}

.cards-list {
  margin-top: 20px;
}
</style>