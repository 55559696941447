<template>
  <div class="bottom">

      </div>
</template>

<script>
  export default {
    data() {
      return{
        
      }
    },
    methods: {
        toPage(index) {
          if (index == 0) {
            this.$router.push("/")
          } else if (index == 1) {
            this.$router.push("/task")
          } else if (index == 2) {
            this.$router.push("/teacher")
          } else if (index == 3) {
            this.$router.push("/test")
          } else if (index == 4) {
            this.$router.push("/article")
          } else if (index == 5) {
            this.$router.push("/notice")
          } else if (index == 6) {
            this.$router.push("/message")
          } else if (index == 7) {
            this.$router.push("/about")
          } else if (index == 8) {
            this.$router.push("/center")
          } else if (index == 9) {
            this.$router.push("/myWrongAssign")
          }
        }
    },
    created() {
     
    },
    mounted() {
      
    }
 }
</script>

<style  scoped>
  .bottom {
    width: 100%;
    height: 0%;
    background-image: url('../../assets/image/index/index_back.png');
    display: flex;
}
.bottom-one,.bottom-four {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: 100%;
    font-family: '黑体';
    font-size: 14px;
}
.bottom-two,.bottom-three {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20%;
    height: 100%;
    font-family: '黑体';
    font-size: 14px;
}
</style>