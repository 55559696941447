<template>
  <div class="assignInfo">
    <headerPage></headerPage>
    <div class="assignInfo-content">
      <div class="assignInfo-top">
        <div class="assignInfo-title">
          {{ chapter.taskName }} - {{ chapter.name }} - 章节作业
        </div>
        <div class="assignInfo-state">
          {{ flag ? '已提交' : '未提交' }} 总分：{{ total }}分 得分：{{ Math.min(defen, total) }}分
        </div>
        <div class="assignInfo-list">
          <div class="assignInfo-item" v-for="(item, index) in homework" :key="index">
            <div style="margin-top:10px;margin-left:10px">
              {{ index + 1 }}.{{ item.title }}
              <!-- Display dialog generated by AI -->
              {{ item.dialog }}
            </div>
            <div style="margin-top:10px;margin-left:10px;margin-bottom:10px">
              <!-- Render input fields based on question type -->
              <el-radio-group v-model="item.solution" v-if="item.type == 0">
                <el-radio v-for="(e, s) in item.content" :key="s" :label="e.value">{{ e.value }}.{{ e.option
                  }}</el-radio>
              </el-radio-group>
              <el-checkbox-group v-model="item.solution" v-if="item.type == 1">
                <el-checkbox v-for="(e, s) in item.content" :key="s" :label="e.value">{{ e.value }}.{{ e.option
                  }}</el-checkbox>
              </el-checkbox-group>
              <el-input v-model="item.solution" v-if="item.type == 2" size="mini" placeholder="请输入答案"></el-input>
              <el-radio-group v-model="item.solution" v-if="item.type == 3">
                <el-radio label="正确">正确</el-radio>
                <el-radio label="错误">错误</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div style="margin-bottom:20px">
            <el-button @click="submit" size="mini" type="primary">提交</el-button>
          </div>
        </div>
      </div>
    </div>
    <bottomPage></bottomPage>
  </div>
</template>

<script>
import {
  getApeChapterById,
  getApeHomeworkStudentList,
  saveApeHomeworkStudent,
  getApeHomeworkStudentFlag,
} from '../../api/api';
import headerPage from '../../components/header/header';
import bottomPage from '../../components/bottom/bottom';
// Import ts-fsrs
import {
  Card,
  createEmptyCard,
  generatorParameters,
  fsrs,
  Rating,
  State,
} from 'ts-fsrs';

// 定义 getStateValue 函数
function getStateValue(stateValue) {
  if (typeof stateValue === 'number' && State[stateValue] !== undefined) {
    return stateValue;
  } else if (typeof stateValue === 'string' && State[stateValue] !== undefined) {
    return State[stateValue];
  } else {
    return State.New;
  }
}

export default {
  data() {
    return {
      chapterId: '',
      chapter: {},
      homework: [],
      total: 0,
      defen: 0,
      flag: false,
      // 存储对应作业项的卡片对象数组
      cards: []
    };
  },
  components: {
    headerPage,
    bottomPage,
  },
  methods: {
    async generateDialog(message, item) {
      if (!message || message.trim() === '') {
        return;
      }

      const requestData = {
        model: 'deepseek-chat',
        messages: [{
          role: 'user',
          content: `请根据提供的课程标题名生成相关问题,只需要生成一个问题即可，标题名:${message}`
        }],
        max_tokens: 1000,
        stream: true
      };

      try {

        const response = await fetch('https://api.smnet.asia/v1/chat/completions', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer sk-uBuVXy7rqA1KDPSdF09e4fF21e8f4a599dA024E25cA44201`
          },
          body: JSON.stringify(requestData),
        });


        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        // 初始化item的dialog属性
        this.$set(item, 'dialog', '');

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          const chunk = decoder.decode(value);
          const lines = chunk.split('\n');

          for (const line of lines) {
            if (line.trim() === '' || line === 'data: [DONE]') continue;

            try {
              const json = JSON.parse(line.replace(/^data: /, ''));
              const content = json.choices?.[0]?.delta?.content;

              if (content) {
                // 更新特定item的dialog
                item.dialog = (item.dialog || '') + content;
              }
            } catch (e) {
              continue;
            }
          }
        }
      } catch (error) {
        console.error('生成对话时出错:', error);
        item.dialog = '生成提示时出现错误，请稍后重试';
      }
    },
    async analyzeAnswer(answer, question) {
      console.log("评级分析开始", answer, question);
      if (!answer || !question) {
        return Rating.Again;
      }

      const timeout = 7000; // 7秒超时

      const fetchPromise = async () => {
        const requestData = {
          model: 'deepseek-chat',
          messages: [{
            role: 'user',
            content: `请对下面内容进行分析，返回一个评级，评级应该在1，2，3，4中选择一个，1是最差，4是最好，输出的内容用【】包裹，例如【3】。问题：${question} 答案：${answer}`
          }],
          max_tokens: 1000
        };

        try {
          const response = await fetch('https://api.smnet.asia/v1/chat/completions', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer sk-uBuVXy7rqA1KDPSdF09e4fF21e8f4a599dA024E25cA44201`
            },
            body: JSON.stringify(requestData)
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          const ratingText = data.choices[0].message.content;
          const ratingMatch = ratingText.match(/【(\d)】/);

          if (ratingMatch) {
            const rating = parseInt(ratingMatch[1]);
            switch (rating) {
              case 1: return Rating.Again;
              case 2: return Rating.Hard;
              case 3: return Rating.Good;
              case 4: return Rating.Easy;
              default: return Rating.Again;
            }
          }
          return Rating.Good;
        } catch (error) {
          console.error('评级分析出错:', error);
          return Rating.Good;
        }
      };

      try {
        return await Promise.race([
          fetchPromise(),
          new Promise((_, reject) =>
            setTimeout(() => {
              console.warn('评级分析超时，使用默认评级');
              reject(new Error('Timeout'));
            }, timeout)
          )
        ]);
      } catch (error) {
        console.error('评级分析失败:', error.message);
        return Rating.Good;  // 超时或其他错误时返回默认评级
      }
    },
    getApeChapterById() {
      getApeChapterById({ id: this.chapterId }).then((res) => {
        if (res.code == 1000) {
          this.chapter = res.data;
        }
      });
    },
    getApeHomeworkStudentList() {
      var param = {
        chapterId: this.chapterId,
      };
      getApeHomeworkStudentList(param).then((res) => {
        if (res.code == 1000) {
          this.homework = res.data;
          for (let i = 0; i < this.homework.length; i++) {
            var item = this.homework[i];
            // 处理 item
            this.total += item.score;
            this.defen += item.reps;
            if (item.type == '0' || item.type == '1') {
              if (item.content) {
                item.content = JSON.parse(item.content);
              }
              if (item.type == 1) {
                if (item.solution) {
                  item.solution = item.solution.split(',');
                } else {
                  item.solution = [];
                }
              }
            }

            // 为每个item生成独立的dialog
            this.$set(item, 'dialog', ''); // 初始化dialog属性
            this.generateDialog(item.answer, item); // 传入item对象

            // FSRS 集成
            var savedItem = item;
            if (savedItem && this.flag) {
              var card = {
                due: savedItem.due ? new Date(savedItem.due) : new Date(),
                stability: Number(savedItem.stability) > 0 ? Number(savedItem.stability) : 0.1,
                difficulty: Number(savedItem.difficulty) >= 1 && Number(savedItem.difficulty) <= 10 ? Number(savedItem.difficulty) : 5,
                elapsed_days: Number(savedItem.elapsedDays) >= 0 ? Number(savedItem.elapsedDays) : 0,
                scheduled_days: Number(savedItem.scheduledDays) >= 0 ? Number(savedItem.scheduledDays) : 0,
                reps: Number(savedItem.reps) >= 0 ? Number(savedItem.reps) : 0,
                lapses: Number(savedItem.lapses) >= 0 ? Number(savedItem.lapses) : 0,
                state: savedItem.state !== undefined ? getStateValue(savedItem.state) : State.New,
                last_review: savedItem.lastReview ? new Date(savedItem.lastReview) : null,
              };
              console.log('修正后的卡片对象：', card);
              this.cards.push({ id: item.id, card: card });
            } else {
              // 如果找不到 savedItem，创建新的 Card
              var card = createEmptyCard();
              this.cards.push({ id: item.id, card: card });
            }
          }
        }
      });
    },
    submit() {
      this.$confirm('确定要提交作业内容吗？', '提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          try {
            for (let i = 0; i < this.homework.length; i++) {
              var item = this.homework[i];
              var cardObj = this.cards.find((c) => c.id == item.id);
              if (cardObj) {
                var card = cardObj.card;
                //console.log('当前的卡片对象：', card);
                // 确定评级
                const rating = await this.analyzeAnswer(item.solution, item.dialog);
                const f = fsrs();
                const now = new Date();

                try {
                  const schedulingCards = f.repeat(card, now);
                  //console.log('调度卡片：', schedulingCards);

                  if (schedulingCards && schedulingCards[rating]) {
                    const updatedCard = schedulingCards[rating].card;
                    cardObj.card = updatedCard;
                    // 更新作业项
                    item.due = updatedCard.due;
                    item.stability = updatedCard.stability;
                    item.difficulty = updatedCard.difficulty;
                    item.elapsedDays = updatedCard.elapsed_days;
                    item.scheduledDays = updatedCard.scheduled_days;
                    item.reps = updatedCard.reps;
                    item.lapses = updatedCard.lapses;
                    item.state = updatedCard.state;
                    item.lastReview = updatedCard.last_review;
                    //console.log('修正后的作业项：', item);
                    //console.log('修正后的卡片对象：', card);
                  } else {
                    console.error('无法获取调度卡片，rating:', rating);
                  }
                } catch (error) {
                  console.error('调用 fsrs.repeat() 时发生错误：', error);
                  continue; // 跳过当前作业项，继续下一个
                }
              } else {
                console.error('未找到对应的卡片对象，作业项ID:', item.id);
              }
            }
            var param = {
              homework: this.homework,
            };
            console.log('提交方法开始执行');
            console.log('当前的作业列表：', this.homework);
            saveApeHomeworkStudent(param).then((res) => {
              if (res.code == 1000) {
                this.$message({
                  type: 'success',
                  message: '提交成功!',
                });
                this.$router.push('/taskInfo?id=' + this.chapter.taskId);
              } else {
                console.error('保存作业失败:', res.message);
              }
            });
          } catch (error) {
            console.error('提交作业时发生错误：', error);
          }
        })
        .catch(() => {
          // 处理取消提交
        });
    },
    getApeHomeworkStudentFlag() {
      return getApeHomeworkStudentFlag({ id: this.chapterId }).then((res) => {
        console.log(res);
        if (res.code == 1000) {
          this.flag = true;
          // Save the returned homeworkList from Java
          //this.homeworkList = res.data;
        } else {
          this.flag = false;
        }
      });
    },
  },
  mounted() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    var chapterId = this.$route.query.id;
    if (chapterId) {
      this.chapterId = chapterId;
      console.log('当前章节ID：', this.chapterId);
    }
    this.getApeChapterById();
    this.getApeHomeworkStudentFlag().then(() => {
      this.getApeHomeworkStudentList();
    });
  },
};
</script>

<style scoped>
@import url('../../assets/css/assign/assignInfo.css');
</style>